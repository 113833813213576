export default {
  language: {
    name: "English"
  },
  common: {
    header: {
      ylkjhyn: "Welcome to Yinli InnoBuilder",
      zjdl: "Recent login",
      grzx: "Personal Center",
      tcdl: "Log out",
      zy: "Home",
      bl: "Cases",
      blzx: "Consultation",
      bzzx: "Help",
      ssts: "Search by patient information",
      wzdl: "Dismiss",
      qpd: "Wear Record",
      qtx: "Continue",
      hfyz: "Reply",
      ckqb: "View All",
      qtj: "Continue",
      qsh: "Review",
      qpz: "Approved scheme",
      ckyy: "To check the reason",
      qck: "Please check"
    },
    message: {
      isLt20M: "Upload file cannot exceed 20M",
      supportedFormats: "Upload file formats bmp、jpg 、png",
      xzpb: "Add New Schedule",
      czcg: "Operation successfully.",
      szcg: "Setup successfully.",
      xgcg: "Modify successfully.",
      qtxwz: "Please complete",
      qxzjl: "Please select a record.",
      qxz: "Please select",
      ycl: "Processed",
      sfjx: "Please confirm to proceed.",
      qd: "Confirm",
      qr: "Confirm",
      qx: "Cancel",
      xz: "Add",
      xg: "Edit",
      xq: "Detail",
      sc: "Delete",
      sz: "Set up",
      fh: "Go back",
      cx: "Search",
      cz: "Reset",
      qb: "All",
      hqyzm: "Generate key",
      bdcg: "Binding successfully.",
      yxbdcg: "Mailbox binding successfully.",
      dlmmszcg: "Password setup successfully.",
      qtxzqyx: "Please fill in a correct email.",
      qtxxyx: "Please fill in new email.",
      qtxsjh: "Please fill in mobile number first.",
      qzqtxsjh: "Please fill in a correct mobile number.",
      bnbdxtsjh: "The same mobile phone number cannot be bound",
      mmbyz: "The password is incorrect, please verify.",
      qtxzqsj: "Please fill in a correct mobile number.",
      sjhbdcg: "Mobile number binded successfully.",
      zjmmszcg: "Payment password setup successfully.",
      sjhbnwk: "Mobile number cannot be empty, please log out and try again.",
      sjhxgcg: "Mobile number has been modified successfully.",
      sjhbnk:
        "Phone number cannot be empty, please return to the home page and try again.",
      zdfh: "Return automatically",
      nn: "Male",
      nv: "Female",
      // cs
      ycbbjbkcz:
        "The tooth has been marked missing and is currently inoperable.",
      yzc: "Save as Draft",
      wjsccg: "File uploaded successfully.",
      wjsc: "File uploaded",
      tpsccg: "Picture uploaded successfully.",
      scsb: "Upload failed, please try again.",
      qxzsctp: "Please select photos to upload.",
      tj: "Submit",
      qqrww: "Please confirm before submitting.",
      qtlcczsm: "Other Instructions"
    },
    common: {
      down: "Down",
      up: "Up",
      qd: "Confirm",
      qx: "Cancel",
      shxh: "(Maxillary, Mandibular & Bite Registration)",
      fd: "Zoom In",
      sx: "Zoom Out",
      sxfz: "Upside Down",
      zyfz: "Turn Around",
      zz: "Left-handed rotation",
      yz: "Dextroversion",
      xz: "Download",
      blxq: "Case Details",
      zwsj: "Not Data",
      zzzhz: "No 3D plan yet",
      qsrnr: "Others (Limited to 2000 characters）.",
      jgwqy:
        "Please contact your local Yinli sales representative to enable this product"
    },
    lcph: {
      cklcph: "View Clinical Preferences"
    },
    component: {
      ybbj: "Tooth Marked Inoperable.",
      lyqyl: "IPR Amount",
      zlq: "Before this Stage",
      fj: "Attachment",
      qsy: "Missing Tooth/Tooth Cavity",
      y: "Right",
      z: "Left"
    }
  },
  home: {
    home: {
      title: "格登特科技",
      ys: "Doctor",
      syzs: "All Clinic",
      xzbl: "New Case",
      dtjbl: "Action Required",
      dsh: "Wait for receiving",
      ysh: "Received",
      ckqb: "View All",
      blh: "Cases Number",
      zwdcl: "No pending action",
      nmhydcl: "Currently there is no Action Required case～",
      nhmydtj: "Currently there is no Awaiting for Submission case～",
      nhmydpz: "Currently there is no Awaiting for Approval  case～",
      nzshmykd: "There is no delivery information available～",
      nzshmyxxx: "There is no new message～",
      nzshmygg: "There is no new announcement～",
      zsmyblxx: "There is no case information available～",
      sj3d: "Treatment Plan - 3D Treatment Plan",
      kdzz: "Track Order",
      dpzfa: "Awaiting for Treatment Plan Approval",
      ckgd: "View more",
      ysnh: "Hello, Dr.",
      dtjbls: "Pre-Treatment",
      zlzbl: "Treatment in Progress",
      ywcbl: "Treatment Completed",
      yszl: "Doctor Information",
      xx: "Message Board",
      xtgg: "System Announcement",
      ggbt: "Content",
      sj: "Date",
      zwgg: "No update",
      zwqycp: "No product available"
    },
    downloadApp: {},
    publicChildren: {
      msgPage: {
        xxlx: "Type",
        fsz: "Sender",
        xxnr: "Content",
        tssj: "Date",
        cz: "Action",
        zsmyxx: "There is no message yet.",
        qrsc: "Are you sure to delete this message?",
        qzf: "To pay for",
        qqr: "To Confirm"
      }
    },
    personal: {
      personal: {
        title: "Doctor Profile",
        zy: "Home",
        grzx: "Doctor Profile",
        mfyjmx: "Free MailModel",
        lcbl: "Clinical Preferences",
        grxx: "Doctor Information",
        dlzh: "Account Login",
        qyjg: "Clinic",
        jj: "Dr. Introduction",
        ybd: "Verified",
        wbd: "Unverified",
        sjh: "Mobile Number",
        dlmm: "Login Password",
        zjmm: "Payment Password",
        pbgl: "Schedule Management",
        gzr: "Work Day",
        sd: "Period of Time",
        szjg: "Clinic",
        zsmypb: "There is no schedule yet.",
        bztx: `Don't Show Again`,
        qd: "Dismiss",
        lcphtx:
          "Dear doctor, in order for the Scheme Design Center to better provide treatment plans according to your requirements, please edit the relevant options on the Clinical Preferences page.",
        szlcph: "Setting Clinical Preferences"
      },
      scheduling: {
        pb: "Scheduling",
        rzr: "Work Day",
        zy: "Monday",
        ze: "Tuesday",
        zs: "Wednesday",
        zsi: "Thursday",
        zw: "Friday",
        zl: "Saturday",
        zr: "Sunday",
        sd: "Period of Time",
        qssj: "Start date",
        jssj: "End date",
        szjg: "Clinic",
        gzr: "Work Day"
      },
      bandEmail: {
        bdyx: "Bind Email",
        yxh: "Email",
        yzm: "Verification Code",
        cxhq: "Reacquire",
        hqyzm: "Get code"
      },
      bandLoginPaw: {
        dlmm: "Update Login Password",
        sjh: "Mobile",
        yzm: "Verification Code",
        zcqr: "Password updated successfully"
      },
      bandPhone: {
        bdsj: "Bind Mobile Number",
        sjh: "Mobile",
        yzm: "Verification Code"
      },
      bandTradingPaw: {
        zjmm: "Udpate Payment Password",
        sjh: "Mobile",
        zcqr: "Confirm Password",
        yzm: "Verification Code"
      },
      bandWeChat: {
        xgsj: "Update Mobile",
        bdwx: "Bind WeChat",
        tzjs: "More timely notification of cases",
        gzh: "The public",
        wxzh: "WeChat Account",
        wxewm: "QR Code",
        qtxwxh: "Please fill in WeChat account."
      },
      editPhone: {
        xgsj: "Modify Phone Number",
        sjh: "Mobile",
        yzm: "Verification Code",
        kfdh: "Service tel",
        sbdyzm: "Not verification code？",
        lxkf: "Please contact customer service at",
        xsjh: "New Mobile"
      },
      exitEmail: {
        xgyx: "Modify Email",
        yxh: "Email",
        yzm: "Verification Code",
        xyxh: "New Email",
        yxxgcg: "Email modified successfully."
      },
      moreHomePage: {
        xx: "Message Board",
        title: "Message Board"
      },
      msgDetail: {
        ggxq: "Announcement Detail",
        title: "Announcement Detail"
      }
    }
  },
  cases: {
    cases: {
      xytjxffab: "Need to submit the repair plan form",
      rxz: "If selected, be sure to check the patient's mandibular regression",
      cp: "Product Selection",
      title: "Case Management",
      xjbl: "New Case",
      tjbl: "Submit Case",
      cxtj: "Resubmit Case",
      zs: "Clinic",
      zt: "State",
      zxddbh: "Patient ID",
      blh: "Patient ID",
      srblh: "Enter here",
      srhzm: "Enter here",
      lcqk: "Clinical Condition",
      xsqb: "Show All",
      yfq: "Deferred",
      fazlz: "Treatment in Progress",
      xjblsj: "Submission Date",
      zmhz: "There is no patient.",
      zlz: "Treatment in Progress",
      zlzs: "In the treatment",
      qpd: "Start wearing ",
      fyt: " pair of braces",
      ygd: "Archived",
      qpd02: "Record Wear Time",
      qsh: "View Logistic",
      hz: "Patient",
      sc: "Delete",
      qrsc: "Are you sure to delete this case?",
      gblysc: "The case has been deleted.",
      s: "Years old"
    },
    casesCreate: {
      title: "New Case",
      xjbl: "New Case",
      jbxx: "General",
      zdsm: "Condition & Prescription",
      yxsm: "Photograph & STL",
      lltj: "Summary",
      zc: " Save as Draft ",
      syy: "Previous",
      xyy: " Next ",
      sftj: "Ready to submit",
      rntj: "Aligner manufacturing will start once submitting the case.",
      qdtj: "Confirm submission",
      qjc: "Operation failed, please enter all information before submitting"
    },
    inviteFriends: {
      bl: "Case",
      blxq: "Case Detail",
      yqsy: "Download APP",
      ysyq: "YOur doctor is inviting you to download the APP",
      gdxx: "See APP for more information~",
      xztp: "Download photos"
    },
    createImage: {
      yhx: "bitePicture",
      khx: "openAndClosePicture",
      zcmwxx: "Left Side Smile", // !en
      zmwxx: "Positive Smile Like", // !en
      ycmwxx: "Right Side Smile", // !en
      czrxwt: "Please address the following issues before submitting the case:",
      fzcg: "Copy successfully!",
      yemx: "Dental Model",
      mxzqbz: "Taking Impression and Export STL File",
      gxjmx: "Silicone Impression",
      gxjym: "Silicone rubber film",
      yscblh: "InnoBuilder has generated the follwing case number",
      fkd: "Send Express",
      blxh: "Patient ID",
      szmxwz: "STL File",
      zxyj: "The door to take",
      yyyj: "By mail",
      zdxz: "Exceed the maximum limit",
      gxjyh: "Bite Registration",
      bdsc: "Upload",
      se: "Maxillary",
      xe: "Mandibular",
      scstl: "Bite Registration (STL)",
      wj: "File",
      lstj: "History to submit",
      sczip: "Please upload ZIP or RAR file.",
      qt: "Others",
      kqnzp: "Facial and Intraoral Photos",
      zppsbz: "Photograph Standard",
      ktztp: "You may drag and drop photos from your computer.",
      sctz: "Select and drag photos here",
      cmx: "Side Profile",
      zmx: "Frontal",
      wxx: "Frontal Smile",
      sylx: "Upper Arch",
      xylx: "Lower Arch",
      knzcyx: "Intra-oral Right",
      knzwx: "Intra-oral Frontal",
      knycyx: "Intra-oral Left",
      xgp: "Radiographs",
      xgpbz: "Radiograph Standard",
      dcp: "Panoramic Radiograph",
      dwp: "Lateral Cephalogram",
      yxzl: "Other Photos",
      zdten: "Maximum of 10 photos.",
      ck: "View",
      qyfgx: "Anterior Overjet",
      qyfhfgx: "Anterior Overjet"
    },
    createPreview: {
      wwcts:
        "The following information is required to submit your case, please complete and resubmit.",
      jzzl: "Condition & Prescription",
      yxzlsm: "Photograph & STL",
      bltxwz: "Please confirm the information.",
      zs: "Chief Complaint",
      ycwt: "Dental Profile",
      egwt: "Facial Profile",
      zsqk: "Chief Complaint",
      lczk: "Clinical Conditions", //跳转联动
      qtqk: "Other Condition",
      njzye: "Arches", //跳转联动
      ycxx: "Tooth Information",
      jzgx: "Sagittal Relationship",
      zuo: "Left",
      you: "Right",
      fg: "Overjet",
      fh: "Open Bite",
      fhsm: "Open Bite Instructions",
      yd: "Anterior Crossbite/Anteriors Edge to Edge Occlusion",
      bkydyc: "Non-Movable Teeth",
      bkfj: "Do not design attachment teeth",
      hsh: "Posterior Crossbite or Buccal Crossbite",
      zx: "Midline",
      jxzl: "Spacing",
      yjzl: "Crowding",
      sfphz: "TADs/MIA to be incorporated?",
      hgj: "Patient with TMJ issues?",
      tssm: "Special Instructions",
      tjcg: "Case submitted successfully.",
      she: "Maxillary",
      xhe: "Mandibular",
      yjshe: "Crowding (Maxillary)",
      kg: "Expansion",
      shcq: "Maxillary Proclination",
      lmqy: "IPR",
      shlmqusm: "Instructions for deglazing the maxillary adjacent surface",
      yymy: "Distalize Molars",
      yymy: "Distalize Molars",
      yjjxhe: "Crowding (Mandibular)",
      xhkg: "Mandibular Expansion",
      xhcq: "Chin tilt",
      xhlmqy: "Instructions for IPR",
      qksm: "Fact sheet",
      qy: "Anterior teeth",
      ychy: "Right posterior teeth",
      zchy: "Left posterior teeth",
      bk: "Cant",
      ggphtext:
        "You can change your preferences for this patient, and clinical preferences will be applied to your treatment",
      jzqyfh: "Correct anterior tooth inarticulation",
      jzqydrh: "Correct the anterior teeth on the blade",
      jz: "Correct"
    },
    createDiagnosis: {
      yyzk: "Original Tooth Condition",
      qzylzbjjy: "Please mark dentures in the dentition",
      qzylzbjqsy: "Please mark any missing teeth.",
      rzzyg:
        'If you want to make dental crowns, please note the requirements for single consecutive crowns and pontic sections, please limit it to 2000 words (after deleting by mistake, use "Ctrl+Z" to undo the operation)',
      lczkchlx: "Clinical Conditions-Malocclusion",
      qsrsh: "Please enter the color number",
      qzylzbjxybyyw:
        "Please mark the required tooth position in the dentition.",
      sjmb: "Design Goals",
      bzhd: "Standard thickness 0.35-0.5mm",
      hd: "thickness(mm):",
      qsrhd: "Please enter thickness",
      sfpztmfa: "Is the aesthetic scheme approved?",
      rnpztmfah: "If you approve the aesthetic scheme, processing will begin",
      lczkyhgx: "Clinical Conditions-Bite Relation",
      lczkxljx: "Clinical Conditions-Adjacent Gap",
      lczkycwt: "Clinical Conditions-Dental Issue",
      ycxxqy: "Teeth Condition （Missing Teeth)",
      ycxxjy: "Teeth Condition (Denture)",
      lczk: "Clinical Condition",
      mbyase: "Target Tooth Color",
      mblx: "Target Type",
      mbtmd: "Target Transparency",
      cllx: "Material Type",
      sfby: "Teeth Preparation Okay?",
      tmhdyq: "Veneer Thickness Requirements(mm)",
      xfsj: "Repair Design",
      // mqbzbsbdysh:
      //   "Clearly mark the corresponding color number of the color guide",
      sxyw: "Teeth Position",
      yhgx: "Bite Relation",
      ljx: "Adjacent Gap",
      tm: "Veneer",
      guan: "Crown",
      qt: "Inlay",
      xfsj: "Design Requirement",
      mqbzbsbdysh:
        "Clearly mark the corresponding color number of the color guide",
      sxyw: "Desired Teeth Position",
      qzylzbjsxyw: "Please mark the desired tooth position in the dentition.",
      yysx: "Primary Tooth Color",
      kxzqtxxmssx:
        "Additional options can be selected to describe the color system",
      kxzqtxxmsys: "Additional options can be selected to describe the color",
      mbys: "Target Color",
      sfphzzzkdbw:
        "TADs/MIA to be incorporated?-Position of TADs/MIA Anchorage",
      sfphzzzkdzy:
        "TADs/MIA to be incorporated?-Purpose of TADs/MIA Impantation",
      yymry: "Distalize Molars",
      ymwl: "Surface Preparation",
      sjyq: "Design Request",
      exgjsfczyc: "Patient with TMJ issues",
      exgjsfczycinit:
        "If you have symptoms such as temporomandibular joint pain, obvious joint snapping, and mandibular movement disorders, a specialist examination is recommended.",
      yzsfzc: "Periodontal Disease",
      yzsfzcinit:
        "For moderate to severe periodontitis, a specialist examination is recommended.",
      threedsjhqd: "Refer to 3D Treatment Plan",
      zc: "Left Side",
      yc: "Right Side",
      jzzk: "Dentition",
      hxgx: "Horizontal Relationship",
      blxg: "Bad Habit",
      txt1: "Chief Complaint",
      zyjzmb: "Treatment Goal",
      yczkasfl: "Clinical Conditions-Angle classification",
      yczkchlx: "Clinical Conditions-Malocclusion Types",
      xfsjsxyw: "Repair Design-Desired Teeth Position",
      xfsjyysx: "Repair Design-Original Teeth Color",
      xfsjmbys: "Repair Design-Target Color",
      xfsjymwl: "Repair Design-Surface Texture",
      xfsjsjyq: "Repair Design-Design Request",
      ycwt: "Dental Profile",
      ggwt: "Facial Profile",
      gs:
        'Please enter less than 1000 words (if delete by mistake, use "Ctrl + z" to undo).',
      // gs2000:
      //   'Please enter less than 2000 words (if delete by mistake, use "Ctrl + z" to undo).',
      gs2000:
        'Please enter less than 2000 words (if delete by mistake, use "Ctrl + z" to undo).',
      txt2: "Clinical Condition",
      lcqk: "Clinical Condition",
      asfl: "Angle Classification",
      gxfl: "Skeletal Classification",
      chlx: "Malocclusion",
      qtqk: "Other conditions",
      txt3: "Arches",
      txt3ms:
        "STL files of both arches are required for single arch treatment.",
      ckse: "Reference Maxillary Coordinate Arch",
      xehx: "Only Consider Arch Correction",
      txt4: "Tooth Information",
      txt4ms:
        "The below tooth chart is the default state of permanent teeth eruption. Please mark any missing or deciduous teeth.",
      gaptxt3:
        "The below tooth chart is the default state of permanent teeth eruption. Please mark any missing.",
      sjfasmrgbszyx:
        "When designing the plan, the scattered gaps are closed by default. If necessary, please mark the reserved repair gaps",
      txt5: "Non-Moveable Teeth",
      txt5ms: "Such as porcelain, bridge or implant.",
      wu: "None",
      ycbk: "These Teeth should not be Moved",
      txt6: "Not Available for Attachments",
      txt6sm: "Such as porcelain teeth, bridges and others.",
      yxbksj: "These Teeth should not be Moved",
      txt7: "Sagittal Relationship",
      zuo: "Left",
      ff: "Method",
      you: "Right",
      s: "Yes",
      txt8: "Overjet",
      txt9: "Open Bite",
      sfxybdjzq: "Need for Passive Aligner",
      sfxybdjzqts:
        "Apply when upper and lower arches are both used, and when one arch treatment is completed before the other one.",
      wc: "Maintain",
      sjhzqr: "Refer to 3D Treatment Plan",
      ydqysghy: "Depress anterior teeth and raise posterior teeth",
      se: "Maxillary",
      xe: "Mandibular",
      qh: "Full jaw",
      txt10: "Anterior Crossbite/Anteriors Edge to Edge Occlusion",
      qyfh: "Anterior Crossbite",
      bjz: "Maintain",
      txt11: "Posterior Crossbite or Buccal Crossbite",
      gszx: "Improve",
      zcyd: "Move to Left of Patient",
      ycyd: "Move to Right of Patient",
      srjtsz: "Enter Value Here",
      txt12: "Midline",
      txt13: "Spacing",
      yljg: "Keep Spacing",
      txt14: "Crowding Correction",
      jcyjsx: "De-crowding Operation",
      qtlczc: "Other Clinical Operations",
      backjclc: "Crowding Correction-De-crowding Operation",
      backjcqt: "Crowding Correction-Other Clinical Operations",
      hzsfjsbczc: "Patient Accept Wisdom Teeth Extraction?",
      hzsfjsbcqmy: "Patient Accept Premolars Extraction?",
      hzsfjsbchy: "Patient Accept Decayed Teeth Extraction?",
      hzsfjslmqy: "Patient Accept IPR?",
      sfyycxf: "Patient Undergo Teeth Restoration?",
      zssfjb: "Clinic Capable of Implant Anchorage Placement?",
      zssfjbps: "Clinic can Perform CBCT photography?",
      hzsfyzjs: "Patient Undergone Orthodontics Treatment before?",
      jcyjjzfs: "De-crowding Methods",
      kk: "Expansion",
      ss: "Preferred",
      kx: "Optional",
      bk: "Cant",
      cq: "Proclination",
      lmqz: "IPR",
      qy: "Anterior",
      zchy: "Left Posterior",
      ychy: "Right Posterior",
      yymy: "Distalize Molars",
      // yymry: "Distalize Molars",
      yymyms:
        "Note: If you choose 'Disalize Molars', TADs/MIA may be required. Radiograph and lateral cephalogram must be included.",
      by: "Extraction",
      bb: "Do not Extract",
      txt15: "TADs/MIA to be Incorporated into Treatment Plan?",
      srms: "Please enter less than 1000 words.",
      txt16: "Patient with TMJ issues?",
      txt17: "Special Instructions",
      hxgjtswz:
        'Please add the symptom description and design requirements for the subtemporal joint abnormality. Please limit the description to 2000 words (delete by mistake, use "Ctrl+ Z" to cancel the operation). ',
      qtjdxjzq:
        "Please enter instruction for passive aligner, please limit to 2000-character.",
      zx: "Lower Left",
      yx: "Lower Right",
      zs: "Upper Left",
      ys: "Upper Right",
      sfxyfzmy: "Eruption Compensation （VG） Required for First Molar?",
      yljgw: "Spacing Value",
      rxztip: 'In "Crowding Correction", select Distalize Molar',
      yjdjzzyymy:
        "Distal molars should be selected in the treatment of crowding",
      yjmcyc: "Please mark teeth that have erupted in the chart below",
      bzbkydyc: "Please mark non-movable teeth in the chart below",
      kcydbkyd: "Such as porcelain, crown, bridge, etc.",
      kcyfj: "Porcelain teeth, repair bridge body can not bond accessories",
      bzbksjyc:
        "Please mark teeth not suitable for attachment in the chart below.",
      sjfas:
        "When designing treatment plan, closing gap between permanent teeth, and maintaining gap between primary teeth are by default.  If you have special instruction, please mark in the chart below.",
      babysjfas:
        "When designing treatment plan, closing gap between permanent teeth, and maintaining gap between primary teeth are by default.  If you have special instruction, please mark in the chart below.",
      kdjx: "Expand Spacing",
      sxjx: "Retract Spacing",
      jxl: "Spacing Value",
      wcjx: "Keep Spacing",
      gbjx: "Spacing",
      maintain: "Maintain",
      spacing: "Spacing",
      expansion: "Expansion",
      retraction: "Retraction",
      closure: "Closure",
      total: "Total",
      zcxzsm: "The footnote to the left is limited to 50 words",
      ycxzsm: "The footnote to the right is limited to 50 words",
      up: "Up",
      down: "Down",
      zyxgbx: "Note: If selected, please submit X-ray photos.",
      zyxry: "Note: baby teeth are limited",
      kdjxz: "Widen the gap to",
      sxjxz: "Narrow the gap to",
      qyl: "Amount to glaze",
      toglaze: "Go to the glaze",
      controlgap: "Control gap",
      qubtjjl: "Be sure to check the extent of mandibular regression",
      nzrzzkd: "Position of TADs/MIA Anchorage",
      nzrzzkdzy: "Purpose of TADs/MIA Impantation",
      jsdgjz:
        "STL files of both arches are required even for single arch treatment",
      bcyxyc: "Pull out the following teeth"
    },
    createUser: {
      tcbdcp: "Package bundled products",
      qsrtcbdcp: "Please select a bundled product for the package",
      znjx: "Intelligent Analysis",
      jx: "Analysis",
      qk: "Empty",
      jxhqhsxx: "Please verify the information is correct after parsing",
      znjxplaceholder:
        "Intelligent analysis: Paste or input the whole paragraph of text, automatically identify the name, mobile phone number, address, such as: Zhang xx 135xxxxxxxx xx Building xx, Xiacheng District, Hangzhou City, Zhejiang Province",
      s: "Yes",
      f: "No",
      hzjsyt: "Receiving type",
      sjrxm: "Addressee's name",
      zssh: "Clinic receiving",
      trsh: "Others receiving",
      qsrlxdh: "Please fill in the correct contact number",
      qtxsjrxm: "Addressee's name",
      qsrsjrsjhm: "Enter the recipient's mobile phone number",
      sjrsjhm: "Phone Number",
      qtxssqjdz: "Please fill in the province, city and address",
      qtxxm:
        "Please fill in the recipient's name, telephone number and address",
      hzxx: "Patient Information",
      jbxx: "General Information",
      hzh: "Patient ID",
      hzmc: "Patient Name",
      qsr: "Please enter",
      xb: "Gender",
      n: "Male",
      v: "Female",
      csrq: "Date of Birth",
      xzrq: "Select Date",
      sjhm: "Mobile",
      dz: "Address",
      sf: "Province",
      cs: "City",
      qx: "County",
      xxdz: "Address Detail",
      zsxz: "Clinic Selection",
      zsmc: "Clinic",
      zsjs: "Introduction",
      cpxz: "Product Selection",
      cpmc: "Product Selection",
      cpjs: "Description",
      qtxhz: "Please fill in patient's name.",
      qtxxb: "Please select patient's gender.",
      qtxcs: "Please fill in the date of birth of the patient.",
      qtxsjh: "Please fill in a correct mobile number.",
      qxzzs: "Please select a clinic.",
      qxzcp: "Please select a product.",
      yzc: "Saved as Draft",
      gcpwqy:
        "Product not available, please contact your Sales Rep for further detail",
      zjcp: "Additional products do not support clinic switching",
      ggcpbzcqh: "Changed product, clinic switching is not supported",
      nlbxdyfive: "You must be older than five years",
      nlthreedsl: "Age 3-16 years old",
      nlthreetosix: "Age 3-6 years old",
      nlthreetothreeteen: "Age 3-13 years old"
    },
    theDelivery: {
      txkdxx: "Fill in Delivery Information",
      yjsj: "Delivery Time",
      qxzwlgs: "Select Delivery Company",
      qsrwldh: "Fill in Delivery ID",
      qxzfhsj: "Select Delivery Time",
      xzsj: "Select Time",
      fhd: "Express Delivery",
      wjmc: "Product Name",
      qtxwjmc: "Please fill in the product name.",
      sjr: "Recipient",
      qtxsjr: "Please fill in the recipient.",
      sjrsjh: "Recipient Mobile",
      qtxsjh: "Please fill in the recipient's mobile number.",
      dz: "Address",
      wlgs: "Logistics Company",
      qxz: "Please choose",
      wldh: "Shipment Number",
      qtxwldh: "Please fill in the logistics number.",
      fhsj: "Delivery Date",
      mxbz:
        "Please make sure to mark clearly the Patient Name and Patient ID on the teeth models.  ",
      xzrq: "Select date",
      qx: "Cancel",
      qr: "Confirm",
      dgjmx: " Mail Impression",
      xsczl: "Please generate treatment plan first.",
      zndxhsz: "Please enter capital letter and number only.",
      txwz: "Please complete",
      srsjh: "Please fill in a correct mobile phone.",
      czcg: "Submit Successfully"
    },
    maintain: {
      jzwcqk: "Treatment Status",
      dzbcq: "Retainer",
      dzbcqdzfs: "Retainer-Aligner Based on",
      dzbcqczxz: "Retainer-Material Selection",
      dzbcqsfmcfj: "Retainer-Remove Attachment?",
      qzsrkntxqtyy: "Please provide detail in the textbox below",
      shscsfxyfzyhdb: "Occlusal Guide on Lingual side at Upper Jaw?",
      scssfxyylwz: "Reserved Position for Lingual Wire?",
      sfxyccmxqg: "Aesthetic Cutting Required?",
      qyqkxzz:
        'Limited to 300 characters (after deleting by mistake, use "Ctrl+Z" to undo the operation)',
      jzwcrq: "Treatment Completion Date",
      // dzbcq: "Retainer",
      sdblwczlh:
        "Note: One set of retainer is included within the aligner treatment",
      dzfs: "Aligner Based on",
      czxz: "Material Selection",
      sfmcfj: "Remove Attachment?",
      jzwchmxjknzp: "After Treatment Facial and Intraoral Photograph",
      jzwchxgp: "After Treatment Radiographs",
      yhmx: "Dental Model",
      xzrq: "Select date",
      qxzcz: "Please select material",
      ktztp: "You may drag and drop photos from your computer.",
      bltxwz: "Please confirm the information.",
      wwcts:
        "The following information is required to submit your case, please complete and resubmit.",
      zsmc: "Clinic",
      cpmc: "Product Selection",
      zc: " Save as Draft ",
      syy: "Previous",
      xyy: " Next ",
      tj: "Submit",
      lltj: "Summary",
      qrwcsynzqk: "Confirm that the completion is in that case",
      wcqdb: "Completion Case",
      xy: "Yes",
      bxy: "Not Needed"
    }
  },
  casesDetail: {
    casesChildren: {
      operationChildren: {
        fabh: "Treatment Number",
        czsyb: "Instruction",
        d3py: "3D Viewer",
        zlb: "Treatment Form",
        yxzl: "Photographs",
        lsxgyj: "History",
        lccz: "Operation Instruction",
        fzjkyj: "Return visit monitoring advice",
        dblzl: " Case Information",
        yxzljmx: "Photographs",
        xgyj: "History",
        zswjy: "There is no change history yet.",
        zsmylsfa: "There is not past case available~",
        hzxm: "Patient Name",
        sjrq: "Submission Date",
        skjzbz: "Number of Maxillary Aligner",
        xkjzbz: "Number of Mandibular Aligner",
        yjjzzq: "Estimated Treatment Duration：",
        ylzj: "Medical Summary",
        y: "Month",
        ylkjjy: "Yinli Comment",
        ysjy: "Doctor Comment",
        nyqrcfa: "You Confirmed Treatment Plan",
        nypzcfa: "You Accepted Target Plan"
      },
      textPlanChildren: {
        dqfa: "Current Plan",
        lsfa: "Historical Plan"
      },
      allLogistics: {
        title: "Logistics Tracking",
        qbwl: "All Logistics",
        ydh: "Logistics Number",
        wjnr: "Content",
        ckxq: "See Detail",
        dsh: "Awaiting for Shipment",
        ysh: "Shipment Confirmed"
      },
      amendments: {
        tjxgyj: "Submit Changes",
        xgyj: "Amendments",
        zcwrxtj: "Save as draft completed.  Resume from submission page.",
        myfa:
          "Simulation program, the actual treatment effect may be different",
        ckls: "View history",
        srxgyj: "Please enter your comments within 500 words.",
        xgyjlr:
          "The modification comments submitted by you will be entered into the system as formal design requirements, and asmile will make design changes as soon as possible according to your requirements.",
        qxtx: "Please fill in the revision comments",
        ckbl: "View case",
        qdwz: "View text plan",
        fhzl: "Review the treatment plan",
        czcg: "Successful operation",
        czsb: "Operation failed, please log out and try again!"
      },
      casesDoKeep: {
        zbc: "Do keep",
        blh: "Patient ID",
        djf: "Number",
        ycxsm: "You have rescanned",
        cxsm: "Rescan",
        ys: "Color",
        cz: "Texture",
        qxz: "Please choose",
        qtxwz: "Please complete",
        qsrys: "Please enter a color",
        qxzcz: "Please select material",
        qrjx: "Please confirm whether to continue.",
        qx: "Cancel",
        qd: "Confirm"
      },
      casesDone: {
        wcbl: "Complete case",
        gdbl: "Archive case",
        blh: "Patient ID"
      },
      casesToScan: {
        zbc: "Do keep",
        cxsm: "Rescan",
        yhmx: "Dental Model",
        mxbz: "Dental Model Standard",
        gxmx: "Silicone Impression",
        xgjym: "Mail Impression",
        blxh: "InnoBuilder has generated the case number",
        fkd: "Send Express",
        blxh01: "Patient ID",
        gjyhe: "Bite Registration",
        szmx: "Digital File",
        xzgjym: "Please select a silicone model",
        fzcg: "Copy successfully",
        xzzip: "Please select a ZIP or RAR file upload."
      },
      casesTransfer: {
        zrbl: "Transfer Case",
        blh: "Patient ID",
        zrzs: "Transfer to",
        zrys: "Transfer Doctor",
        zrly: "Reason for Transfer",
        qsrwz: "Transfer completed.",
        gzswys:
          "There is no doctor under this clinic, please choose another clinic."
      },
      hisEditOpinion: {
        lsxgyj: "Suggested changes to history",
        fabh: "Plan number"
      },
      hisTextPlan: {
        wzlsxgyj: "Comments on the historical revision of the text plan",
        fabh: "Plan number"
      },
      lookWearingRecord: {
        ckpdjl: "View Wear Records",
        dqpd: "Currently wearing",
        d: "Aligner ＃",
        f: "副", //暂时不用
        pc: "lot",
        ytzbs: "Total retainer steps",
        cmpzh:
          "This diaphragm combination is recommended for scheme design, if you need to change it, it will take effect after scheme approval",
        rnpzh:
          "If you approve the 3D treatment, the company will begin processing the orthotics",
        gsksjg:
          "The diaphragm combination is recommended for scheme design. If you need to change it, click modify",
        kspdsj: "Start Date",
        yjssj: "Planned End Date",
        dqpchs: "当前批次还剩", //暂时不用
        t: "套", //暂时不用
        xytpd: "Reminder Date for Next Set",
        ggtxsj: "Change Reminder Date",
        xzrq: "Select Date",
        zwpdjl: "No wear record."
      },
      nextProduction: {
        xypsc: "Next Shippment",
        fhsyy: "go back",
        blh: "Patient ID",
        cxtz: "Need Correction",
        wxtz: "Send Next Shippment"
      },
      paymentScheme: {
        zfsjf: "Payment of design fee",
        zfsc: "Pay manufacturing costs",
        blh: "Patient ID",
        fasjf: "Scheme design fee",
        sczzf: "Manufacturing costs",
        scf: "Production expenses",
        y: "￥",
        zhye: "Account Balance",
        sxye: "Credit balance",
        srzfmm: "Payment password",
        zzfy: "*After payment, you can view the complete treatment plan",
        jrsczz: "*After payment, the manufacturer enters production",
        zfsb: "Payment failed, please quit and try again",
        zfcg: "Payment successful",
        qtxzfmm: "Please fill out the payment password",
        qxszfmm: "Please set the payment password first",
        yebz: "Insufficient balance, please recharge",
        sxyebz:
          "Your account balance is insufficient, please contact the clinic administrator to top it up",
        qrzf: "Confirm payment",
        dzfscf: "Production charges to be paid",
        dqrsc: "Awaiting for Confirmation",
        sfqdzfscf: "Are production charges determined to be paid",
        zfhgsksjgsc:
          "After the payment, the company began processing and production",
        qrhksjg: "Production to start after confirmation",
        qrsc: "Production confirmed",
        sfqrsc: "Confirm production?"
      },
      restartCases: {
        cqbl: "Mid-Course Correction",
        blh: "Patient ID",
        srzfmm: "Payment Password",
        cqcztip: "*Case will restart once ordering the Mid-Course Correction.",
        qsrzfmm: "Please enter the payment password before proceeding."
      },
      reviewPlan: {
        fhzlfa: "Review the treatment plan",
        blh: "Patient ID",
        pzcfa: "Confirm Tx Plan",
        qrcfa: "Accept Target Plan",
        tjxgyj: "Place Comment",
        sfjxpz: "Do you accept the Target Plan?",
        sfpz3d: "Do you confirm the Treatment Plan?",
        rrpz: "Approve",
        rnpzkssc3d:
          "If you approve the 3D treatment plan, Yinli Technology will start manufacturing the appliances.",
        rnpzkssc:
          "Once you accpet the initiail treatment plan, Yinli Technology will start designing the 3D treatment plan."
      },
      reviewTextPlan: {
        qdwzfa: "Determine text plan"
      },
      sendAdvice: {
        fsyz: "Consultation Request",
        yzlx: "Type",
        smsj: "Home time",
        yznr: "Remark",
        fs: "Send",
        lsyz: "Consultation Request History",
        zwyz: "No consultation request yet.",
        qxzyzlx: "Please select the type of consultation request.",
        qxzsmsj: "Please choose the date.",
        qsryznr: "Please enter the detail.",
        fscg: "Consultation request sent successfully."
      },
      viewLogistics: {
        ckwl: "View Logistics",
        ydh: "Logistics Number",
        wjnr: "Content",
        qrsh: "Confirm Shipment",
        zswwl: "No update yet.",
        dsh: "Awaiting Confirmation",
        ysh: "Shipment Confirmed"
      },
      wearingBraces: {
        pdyt: "Aligner Wear Record",
        blh: "Patient ID",
        djfyt: "Aligner Numuber",
        pdkssj: "Start Date",
        yjssj: "End Date",
        pdssm: "Remark",
        fs: "Send",
        pdjl: "Wear Record History",
        d: "第",
        t: "套",
        zwpd: "No wear record yet.",
        qsryt: "Please enter the number of aligner.",
        qxzpdsj: "Please select the start date.",
        xzpdjs: "Please select the end date."
      },
      common: {
        bl: "Case",
        blxq: "Case Details",
        fh: "Go back",
        qr: "Confirm",
        czcg: "Successful operation",
        czsb: "Operation failed, please log out and try again.",
        czsbErr:
          "Something went wrong, please close the current window and try again.",
        qrjx: "Please confirm whether to continue.",
        qx: "Cancel",
        qd: "Confirm",
        qxz: "Please choose",
        fhsyy: "Go back",
        qsr: "Please enter",
        xzrq: "Select date",
        zzzhz: "No 3D plan yet",
        zwsj: "No Data"
      }
    },
    childrenPublic: {
      backStep: {
        fhsyy: "Go back"
      }
    },
    casesDetail: {
      kqrdth:
        "After iRDT is enabled, the Solution design Center will give a new solution design",
      kqrdt: "Turn on iRDT",
      kqrdtxyws:
        "Starting iRDT needs to improve [diagnosis and description], and the scheme design center will give a new scheme design after submission",
      ddzl: "Order Form",
      ddh: "Order Number",
      ddxm: "Order Name",
      title: "Case Information",
      blh: "Patient ID",
      ck3d: "View 3D Plan",
      lcqk: "Clinical Condition",
      tjbz: "Add notes",
      qsr: "Please enter less htna 1000 words.",
      bc: "Save",
      qx: "Cancel",
      xg: "Edit",
      lc: "Case",
      cz: "Information",
      qpd: "Record Wear Record",
      yfqkcq: "Obsolete, restartable",
      blzl: "Treatment Form",
      zlk: "Data bank",
      sjjjd: "Gathering phase",
      z: "Set",
      f: "Form",
      g: "Video",
      yxzl: "Photographs",
      zlfa: "Treatment Programs",
      tdfa: "3D Plan",
      tdzlfa: "3D Treatment Plan",
      mbwfa: "Target Plan",
      sjjd: "Treatment Planning",
      ypz: "Have been permitted",
      wpz: "Unapproved",
      yqr: "Acknowledged",
      wqr: "Unconfirmed",
      yfk: "Have feedback",
      sjjd01: "Pre-Treatment",
      jd: "Stage",
      wjm: "File Name",
      scjd: "production Phase",
      ytjd: "Braces progress",
      zljd: "Treatment",
      bcjd: "Post-Treatment",
      wcjd: "Completion stage",
      scbcq: "Order Retainer",
      wlgz: "Shipment Information",
      threedsj: "3D Treatment Plan",
      mxfa: "Aesthetic Scheme",
      threedsjsm: "3D Correct Instructions",
      fasm: "Plan Instructions",
      ckqb: "View all",
      fh: "Ship",
      ckwl: "View Shipment Detail",
      zwwlxx: "No update yet.",
      bljd: "Treatment Timeline",
      ckpdjl: "View Wear Records",
      dtj: "To be submitted",
      zlz: "In treatment",
      ywc: "Completed",
      ygd: "Archived",
      yfq: "Deferred",
      hzhd: "Patient Interaction",
      yqsyapp: "Download APP",
      pdyt: "Record Wear Time",
      fsyz: "Send Consultation",
      fasj: "Treatment Planning",
      tjzlb: "Submit TX Form",
      zjsc: "Additional production",
      zjcp: "Clinic switching is not supported",
      cklszj: "View history append",
      zjscjd: "Additional Production Progress",
      wdsj: "Not available",
      yzt: "Paused",
      qdwzfa: "Accept Target Plan",
      zfsjf: "Pay the design fee",
      qdzlfa: "Confirm Treatment Plan",
      pztdfa: "Approve 3D Scheme",
      pzmxsjfa: "Approve Aesthetic Scheme",
      scjg: "Case Manufacture",
      zfscf: "Pay production costs",
      jxfh: "Order Next Shipment",
      gxjyj: "Mail Dental Impression",
      blcz: "Case Operation",
      ztm: "Transfer Veneer",
      wcbl: "Complete Case",
      gdbl: "Archive Case",
      cqbl: "Order MCC",
      zscz: "Clinic Operation",
      zrbl: "Transfer Case",
      gztbkcz: "This operation is not available in this stage",
      qtxbz: "Please fill in the remarks",
      bztjcg: "Notes added successfully",
      lcczsm: "Operation Instructions",
      bu: "Step",
      shzz: "Maxillary All",
      xhzz: "Mandible All",
      gcffz: "Factory release to maxilla",
      shd: "Maxillary",
      xhd: "Mandibular",
      bcjg: "The processing",
      sfhxsq: "Whether to submit subsequent processing applications",
      ggdz:
        "If you need to change the address, please contact our customer service staff",
      jzqsh: "Receiving the appliance",
      jghx: "Subsequent processing"
    },
    publicCases: {
      casesDiagnosis: {
        zdyq: "Diagnosis and Description"
      },
      casesPic: {
        yxzl: "Photographs",
        mxkq: "Facial and Intraoral Photographs",
        xpg: "Radiographs",
        qtyx: "Other Photos"
      },
      casesUser: {
        jbxx: "General Information",
        n: "Male",
        v: "Female",
        yljg: "Medical Institutions",
        blxh: "Patient ID",
        fzcg: "Copy successful!"
      }
    },
    clinicalPreferences: {
      qqlcph: "Global Clinical Preferences",
      cptdph: "Product Specific Preferences",
      qbzd: 'Please specify in "Special Instrcutions" section.',
      phjs:
        "The clinical preferences listed below are applied by default to all of your treatments. When submitting patient cases, you can change preferences for individual cases.",
      bc: "Save",
      cplcph:
        "The following clinical preferences will only be applied to your first stage of treatment.",
      smsRTD: "What is iRDT Assist",
      kqRTD:
        "After activating iRDT assistance, the treatment form submitted by the doctor will be refined by experienced orthodontists, as well as the plan reviewed, and finally presented to the doctor for approval.",
      dx: "Select One Only",
      duox: "Select Multiple",
      lcphsz: "Clinical Preferences update successfully.",
      qsr: "Please enter",
      RTD: "RDT Assist",
      sfxyRTD: "Do you need iRDT assistance",
      xy: "Yes, proceed",
      bxy: "No, thank you"
    },
    view3d: {
      hz: "Patient",
      blh: "Patient ID",
      lsfa: "Historical Plan",
      jzsm: "Treatment Form",
      blzl: "Cases data",
      zlb: "Summary",
      zp: "Photograph"
    }
  },
  helpCenter: {
    title: "Help Center"
  },
  login: {
    login: {
      etzhxtxzj: "Comprehensive systemic early correction for children",
      hyld: "Welcome to",
      yxjzyspt: "MOST-based invisible orthodontic doctor platform",
      dljszc:
        "Duole Tianxiang Clinical Technology Group and Yinli Technology provide support",
      title: "Welcome to Yinli Technology InnoBuilder Platform",
      srzh: "Please enter account",
      srmm: "Please enter password",
      jszc: "Supported by Yinli Technology Co.Phone Number：4000 538 818",
      gfwz: "Website：www.aigrin.cn",
      dl: "Login",
      cz: "Reset",
      wjmm: "Forget password",
      xzapp: "Download APP",
      jzmm: "Save Password",
      xgmm: "Forgot Password",
      szh: "Yinli Technology InnoBuilder Platform",
      hyldylpt: "Welcome to InnoBuilder Platform",
      qsryhm: "Enter User ID"
    },
    retrievePaw: {
      wjmm: "Forget Password",
      sjh: "Mobile",
      srsjh: "Please enter phone number",
      cxhq: "Reacquire",
      hqyzm: "Get code",
      yzm: "Verification Code",
      sryzm: "Please enter verification code",
      xmm: "New Password",
      srxmm: "Please enter a new password",
      qrmm: "Confirm Password",
      qqrmm: "Please confirm your password.",
      qxtxsjh: "Please fill in the mobile phone number first.",
      qzqtx: "Please fill in the mobile phone number correctly.",
      mmbyz: "Passwords are inconsistent, please confirm.",
      qd: "Confirm",
      fh: "Go back",
      czcg: "Operation Successfully!",
      xmminit:
        "Please set a 6 - to 20-digit password, including letters and numbers"
    },
    downloadApp: {
      smxz: "Scan Code Download"
    },
    common: {
      qtxwz: "Please complete information."
    }
  },
  birdie: {
    qxzjjdz: "Select Recipient Address",
    dqjgdz: "Current Address",
    gtxd:
      "Current address is a shared address.  Staff and personnel can help placing order.",
    grdz: "Personal Address",
    xzgrdz: "New Personal Address",
    xz: "Select",
    bj: "Compile",
    djsmx: "Model Delivery",
    yxdmx: "Past Orders",
    xzjg: "Select Clinic",
    jgewm: "Clinic QR Code",
    xm: "Name",
    qgxbl: "Select Patient",
    jxtj: "Continue",
    xd: "Place Order",
    bctp: "Save Picture",
    dy: "Print",
    ylkjmfjs: "Free delivery of dental model",
    smjsmx: "Scan QR Code",
    smdkxd: "Scan QR code to open order page",
    gjmxgx: "Select the corresponding patient",
    xdwcdd: "Awaiting for delivery personal to pickup order",
    wlsmzq:
      "You may cancel your order anytime before delivery personnel picking up the order.  Please contact customer support for any additional support. ",
    mx: "Model",
    qxjsh: "You may order again after cancellation.",
    qdqxjs: "Confirm Cancellation",
    xzgedz: "New Personal Address",
    bjgedz: "Edit Personal Address",
    qhsryw: "wrong area code",
    dhhmsryw: "Incorrect phone number input",
    jjr: "Sender",
    xzdq: "Sender City",
    lxdh: "Sender Phone",
    gddh: "Recipient Phone",
    qxzjddz: "Recipient Address",
    qxzdq: "Recipient City ",
    dhgscw: "Phone number format is incorrect",
    sjrsjhmyw: "Phone number format is incorrect",
    qtxsjh: "Please enter phone number.",
    qtxjjr: "Please fill in the sender",
    wlxx: "Delivery Information",
    tjjsmx: "Add Model",
    tj: "Add",
    qrddxx: "Verify information",
    ydwcdd: "Awaiting for delivery personal to pickup order",
    nkycx: "You may review logistics detail in Model Delivery Page",
    cxxd: "Replace Order",
    xdsb: "Order Unsuccessful",
    smsj: "Pickup Time",
    jg: "Clinic",
    qrxd: "Confirm Order",
    rhsy: "How to",
    dqjgzwmx: "There is no model available for delivery.",
    jt: "Today",
    mt: "Tomorrow",
    ht: "Day After",
    qsrblhxm: "Please enter patient name or ID.",
    bjjjjjdz: "Edit Clinic Address",
    qwbbz: "Please clearly write down patient's name and ID.",
    qtxlxr: "Please fill in the contact name",
    yjg: "There are",
    yng:
      "cases, the mailing address is personal address, please change the address to place the order",
    yjgdz:
      "cases, the mailing address is clinic address, please change the address to place the order",
    ts: "Hint",
    qxgx: "Please check this box before placing an order",
    lxsyn: "Within two hours",
    ckyxdmx: "View the ordered model",
    sfqrxd: "Confirm the order",
    qxgxsj: "Please select the time of visit first",
    yqx: "Canceled",
    qxjs: "Cancel sending",
    qjm: "Take a code",
    zdsg: "Maximum of 3 Address can be added",
    dqgrdz: "Pickup Code",
    qh: "County Code",
    dhhm: "Phone Number",
    fj: "Additional Phone Number",
    qtxqh: "Please enter district code",
    qtxdhhm: "Please enter phone number",
    gr: "Personage",
    qsrswsz: "Please enter 4 digits",
    qsrbwsz: "Please enter 8 digits",
    xdz: "Order in progress",
    xdsj: "Order Submission Time",
    qjhm: "Take a code",
    yysmsj: "Arrange Pickup Time",
    kdxx: "Courier Information",
    qxzsmsj: "Please select the time of visit",
    xj: "New",
    wssdbl: "Case not found",
    knczyy: "Here are some possible reasons",
    wzqsrxm: "Incorrect name or ID",
    bsydqjg: "Patient belongs to different clinic",
    xdddz: "Order processing, please do not close window",
    nzshmyyxdmx: "No order has been placed yet",
    nzshmywlxx: "You haven't got the logistics information yet",
    qx: "check all"
  },
  notice: {
    threeD: {
      gxnr: "Update content",
      dhxg: "3D animation modification",
      yskdsjfa:
        "Doctors can modify the target positions, attachments, unglazed gaps, and overall dentition of the 3D design plan",
      xgnrhxgyj:
        "The revised content and suggestions will be fed back to the Scheme Design Center to further improve the accuracy and efficiency of 3D design scheme communication",
      djyctjxgyj:
        "Click on the right side to submit modification suggestions, and click on the 3D animation again to modify",
      jtdhxggnjs:
        "Introduction to specific 3D animation modification functions, please refer to",
      dhxgczbz: "the help for 3D animation modification operations",
      bjsz: "3D Background Settings",
      yskytjdh:
        "Doctors can adjust the theme of 3D animation pages, choose 3D animation background colors, etc",
      xyg: "Next",
      msty: "Experience Now",
      syg: "Prev"
    },
    caseDetail: {
      gxnr: "Update content",
      jdtz: "Stage adjustment",
      xzjdtz: "Newly added stage adjustment",
      jdtznr:
        "Stage adjustment refers to the need to reproduce the orthodontic device due to the need to adjust the attachment position/deciduous tooth detachment/crown restoration during the patient's orthodontic treatment process without changing the treatment plan. The silicone rubber impression/oral scan model needs to be resubmitted, and the 3D plan needs to be redesigned according to the original plan",
      kck: "Can be viewed",
      help: "how to distinguish between stage restart and segment adjustment",
      zd: "Got it"
    }
  },
  stageAdjust: {
    jdtz: "Stage adjustment",
    dqjzqthqk: "Current appliance fit status",
    pdyj:
      "Judgment basis: The tooth movement and occlusal relationship are basically consistent with the simulated appliance design, and there is no obvious gap between the appliance and the tooth.",
    lcjc: "Clinical examination",
    bcfkspdbs: "The number of steps to wear the appliance during this feedback",
    shd: "Maxillary regulation",
    xhd: "Mandibular adjustment",
    bs: "step",
    sjdsjdbs: `The number of steps of the appliance designed in the previous stage is 1-{totalStep} steps, and {stepDate} delivers {stepSection} steps`,
    fjtz: "Accessory adjustment",
    fjknhtz: "Attachments may be adjusted",
    gjsjfakntz:
      "Other attachments may be adjusted or new attachments added according to the design scheme",
    gjsjfakntj: "New accessories may be added according to the design scheme",
    yljx: "Dentition space",
    qjlfk: "Please record the feedback gap less than 1.0mm",
    qtjc:
      "Other tests (including tooth misalignment, looseness, early contact, etc.)",
    qygjddsjyq: "Design requirements of the previous stage",
    rybbgqysjstz:
      "If there is any change, please ask the doctor to adjust it in time. If not, the company will continue the design requirements of the previous stage",
    bkydyc: "Immovable tooth",
    kcyxftt: "Porcelain teeth, prosthetics, implants, etc. can't move teeth",
    bksjfjyc: "Attachment teeth cannot be designed",
    kcyxfqtwfzt:
      "Porcelain fused to metal teeth, restoration of bridges and other attachments that cannot be bonded",
    hxjzyq: "Subsequent orthodontic requirements",
    bygbcsjzmb: "Initial orthodontic goals should not be changed",
    pdjzqbthbwzp: "Current appliance Does not fit Photograph",
    kqnzp: "Facial and Intraoral Photos",
    yhmx: "Dental Model",
    nxhgjsfyc: "Dentition space subsequent_orthodontic_requirements",
    bthbwzp: "Photos of non fitting parts",
    jjytmx: "First aid braces model",
    fasjmx: "方案设计模型",
    qtmx: "其他模型",
    jdtzmx: "阶段调整模型",
    wcblmx: "完成病例模型",
    mxtips: "当前病例存在多种待邮寄硅橡胶模型，请选择需要添加的硅橡胶模型类型",
    qx: "Cancel",
    qd: "Confirm"
  },
  threeD: {
    picName: {
      sideFacePicture: "Side Profile",
      frontFacePicture: "Frontal",
      smilePicture: "Frontal Smile",
      upToothPicture: "Upper Arch",
      anteriorOverjetPicture: "Anterior Overjet",
      downToothPicture: "Lower Arch",
      mouthRightPicture: "Intra-oral Right",
      mouthFrontPicture: "Intra-oral Frontal",
      mouthLeftPicture: "Intra-oral Left",
      xrayCurvePicture: "Panoramic Radiograph",
      xraySidePicture: "Lateral Cephalogram",

      sideProfile: "Side Profile",
      frontal: "Frontal",
      frontalSmile: "Frontal Smile",
      upperArch: "Upper Arch",
      anteriorOverjet: "Anterior Overjet",
      lowerArch: "Lower Arch",
      intraOralRight: "Intra-oral Right",
      intraOralFrontal: "Intra-oral Frontal",
      intraOralLeft: "Intra-oral Left",
      panoramicRadiograph: "Panoramic Radiograph",
      lateralCephalogram: "Lateral Cephalogram"
    },
    czdbandfa:
      "1. For schemes with a comparison button, you can click on the scheme to view the comparison",
    dbzqfaja:
      "2. Compared to earlier solutions, the loading time is slightly longer. Please be patient and wait",
    db: "Contrast",
    gbdhxg: "Close modification",
    ckdhxg: "View changes",
    threeddhxg: "3D modification",
    mpczbczczlb: "Diaphragm material does not exist in the material list",
    // rjjzdjl: 'The software will automatically record the 3D modification result, automatically save current data every 2 minutes',
    rjjzdjl:
      "The software will automatically record the 3D modification result",
    xz: "newly added",
    xgjx: "edit",
    qc: "remove",
    jx: "gap",
    sc: "Delete",
    fj: " attachment",
    to: "To",
    fjjx: "Rectangle",
    ty: "Elliptic",
    tx: "Trapezoid",
    hd: "Jaw pad",
    byh: "Semicircle H",
    bys: "Semicircle H",
    ylj: "Pressure ridge",
    yhdb: "Occlusal guide plate",
    ylk: "Gravitational buckle",
    xh: "type",
    lyqy: "Deglaze adjacent teeth",
    shyctz: "Maxillary tooth adjustment",
    xhyctz: "Jaw tooth adjustment",
    yw: "FDI",
    jxs: "Agent",
    fk: "Comments",
    lsfa: "History",
    jzsm: "Instructions",
    blzl: "Data",
    mbfa: "Target program",
    mpcz: "Aligner Material",
    mnfa: "Simulation program, actual treatment effect may vary",
    zp: "Photograph",
    xg: "X-ray",
    qt: "Others",
    zlb: "Summary",
    csd: "Vendor side",
    xsd: "Sales",
    ysd: "The doctor end",
    ylkj: "Yinli Technology",
    zsmytp: "No picture at present",
    ntjd:
      "Submitted comment will be used as instruction for design team to update the treatment plan.  Please contact customer support if you have any question.  ",
    cwbtx: "Filed required",
    qzcsrxgyj: "Please enter comment here",
    qzcsrjzfayj:
      "Please enter corrective treatment modification suggestions here",
    qzcsrxffayj:
      "Please enter suggestions for modifying the repair solution here",
    qzsxtyxfajxtj:
      "Please select at least one scheme to submit modification comments",
    jzfa: "Treatment plan",
    xffa: "Repair plan",
    yjms: "Comment Detail"
  },
  braceReissue: {
    tjsq: "Submit application",
    ytsq: "Dental braces application",
    shjl: "Audit records",
    qxzbshzcz: "Please select the number of steps or material",
    qxzzsshdz: "Please select the delivery address of the clinic",
    qxzgxjmx: "Please select a silicone rubber model or digital model file",
    qscszmxwj: "Please upload the digital model file",
    dczsczyts:
      "When there are braces in production, it is not supported to apply again. Please wait for the production to be completed before submitting a new application",
    ytsqznsq:
      "Dental braces application can only apply for braces that have already been issued in the current plan. Restarting a case cannot apply for braces that were previously restarted",
    ckmpzh: "View diaphragm combination",
    czdyjmx: "There is a pending mailing model,",
    djqwyj: "Click to go to mailing",
    ytxx: "Dental information",
    qzxmstnxz:
      "Please select the braces and materials to apply for in the following view",
    // qxzzsshdz: "Please select the delivery address of the clinic",
    mrbxytjyhmx:
      "By default, there is no need to submit a dental model. Based on the selection of dental braces, previous data will be used for production",
    rgxycyxdmx:
      "If a new model file is required for the production of dental braces, please choose to submit a dental model",
    sqyy: "Reason for application",
    bfxx: "Reissue information",
    sqsj: "Application time",
    shjg: "Audit result",
    shsm: "Audit statement",
    dyjmx: "Model to mail",
    yjmx: "Mailing model",
    zwshjl: "There is no audit record",
    shz: "Under review",
    tg: "Pass",
    btg: "No Pass",
    blzzwjsmx: "Cases have unsent models",
    fwjzrq: "Service deadline",
    qqdsfjxgcz: "Do you want to continue the operation?",
    ztfw: "Suspension of service",
    ztqrhgjpdsj:
      "After the suspension is confirmed, the timing is suspended according to the case restart period calculated by the wearing time, until the next shipment continues or the cumulative timing continues after the restart of the case",
    mcfhhfkczzt: "The operation can be suspended only after each delivery",
    jjyt: "First aid braces",
    gxjmxxz: "Silicone rubber model selection",
    mxxz: "Model selection",
    jjytmx: "First aid braces model",
    fjjytmx: "Non-emergency retainer model",
    dqblczjjyadyj:
      "The current case has a silicone rubber model for emergency braces to be mailed. Please select the type of silicone rubber model you want to add",
    qxzmxlx: "Please select a model type",
    dzxz: "Address selection",
    tj: "Submit",
    btj: "Not submit",
    shb: "Upper jaw (step)",
    xhb: "Mandible (step)",
    mpzh: "Diaphragm combination",
    ytzbs: "Total steps of braces",
    cxtj: "Resubmit",
    czxz: "Material selection",
    qx: "Cancel",
    qd: "Confirm"
  },
  yssh: {
    tjgjjzq: "Submit silicone orthodontic device",
    tjhdjzq: "Submit activity orthodontic device",
    tjyxjzq: "Submit  invisible orthodontic device",
    fadqcyirdt:
      "The plan is currently on the iRDT end and needs to wait for iRDT approval before doctors can approve or submit modification suggestions",
    xytjjzqcfb:
      "Require submission of silicone orthodontic device prescription form",
    xytjhdcfb:
      "Require submission of activity orthodontic device prescription form",
    xytjyxcfb:
      "Require submission of invisible orthodontic device prescription form",
    dzxz: "Address Selection",
    yssh: "Doctor receiving goods",
    ysshdz: "Doctor's shipping address",
    djdzxz:
      "Click on 'Address Selection' to select the doctor's shipping address",
    spjzqshdz:
      "Delivery address of the previous batch of orthodontic appliances",
    tjdz: "Add Address",
    nzwtjrhshdz: "You have not added any shipping addresses yet",
    qxzzsshdz: "Please select the delivery address of the clinic",
    qxzysshdz: "Please select the doctor's shipping address",
    ysdzgl: "Doctor address management",
    xzdz: "New Address",
    bjdz: "Edit address",
    sfmr: "Default or not",
    smqj: "The door to take",
    zxyj: "By mail",
    bdsc: "Upload",
    itero: "iTero",
    qt: "Others"
  },
  //病例咨询
  blzx: {
    hzxm: "Patient Name",
    sex: "Gender",
    birthday: "Date of Birth",
    zs: "Chief Complaint",
    mxplaceholder: "Others (Limited to 2000 characters）",
    by: "Extraction",
    Y: "Yes",
    N: "No",
    zkd: "TADs/MIA to be Incorporated into Treatment Plan",
    zyzxwt: "Main consulting questions",
    mxpic: "Facial and Intraoral Photos",
    psbz: "Photograph Standard",
    tz: "You can drag and drop images from your computer",
    xpic: "Radiographs",
    yhmx: "Dental Model",
    upload: "Upload",
    iTero: "iTero",
    other: "Other",
    fazxb: "Case Consultation Form",
    yltj: "Summary",
    bltxwz:
      "The case consultation has been completed. Please confirm the content of the case consultation",
    xjblzx: "New case consultation",
    all: "Whole",
    inService: "In service",
    toEvaluated: "To be evaluated",
    consultationId: "Consultation order number",
    hzzl: "Patient information",
    age: "Year",
    nn: "Male",
    nv: "Female",
    createDate: "Creation date",
    Operation: "Operation",
    ck: "View",
    del: "Delete",
    toAcc: "Pending acceptance",
    toSub: "Waiting for your submit",
    inPro: "Processing",
    toClo: "Waiting for your closed",
    toFeed: "Waiting for your feedback",
    toCom: "Waiting for your confirmation",
    toEva: "Waiting for your evaluation",
    closed: "Closed",
    describe: "Describe",
    screenshot: "Screenshot",
    screenshotTip:
      "Supports formats such as. png, jpg, PNG, JPG, etc. Each image size should not exceed 20M, and a maximum of 10 images can be uploaded",
    attachment: "Attachment",
    attachmentTip:
      "Supports formats such as. png,. jpg,. txt,. rar,. doc, etc. Each attachment size should not exceed 20MB, and a maximum of 10 attachments can be uploaded",
    zxhf: "Consultation response",
    djsc: "Click to upload",
    tj: "Submit",
    gtjl: "Communication records",
    load: "Load more",
    sale: "sale",
    doctor: "doctor",
    tjTime: "Submission time",
    ddStatus: "Order status",
    qdbgbzx: "Confirm and close consultation",
    gbzx: "Close consultation",
    evaluate: "Evaluate",
    evaluated: "Evaluated",
    turn: "Transferred cases",
    processed: "Processed",
    accepted: "Accepted",
    confirmed: "Confirmed",
    confirmClose: "Confirm Close",
    closeTip:
      "Closing consultation is a non refundable operation. Are you sure you want to close consultation",
    evaluateTip:
      "Please evaluate my service this time. Your suggestions are very important for improving the quality of our service",
    bmy: "Very dissatisfied",
    my: "Very satisfied",
    overall: "Overall experience of this service",
    isHelp: "Case consultation services (whether they are helpful to you)",
    speed: "Consultation response speed",
    attitude: "Consultation service attitude",
    qr: "confirm",
    product: "Product",
    selProduct: "Please select a product",
    clinic: "Clinic",
    selClinic: "Please select a clinic",
    selBind: "Please select the package binding product",
    bindProduct: "Package bundled products",
    idRdtHelp: "Is iRDT assisting",
    need: "Need",
    noNeed: "No Need",
    selRdt: "Please choose whether to assist"
  },
  quillEditor: {
    placeholder: "Please enter",
    img: "picture"
  }
};
